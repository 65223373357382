<template>
  <el-dialog :visible.sync="show" title="订单变更" append-to-body :close-on-click-modal="false" custom-class="custom-dialog-max-width">
    <div style="min-height: 120px" v-loading="loading">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="100px" label-suffix=":" v-if="form">
        <div class="h wrap">
          <el-form-item label="销售单号">
            <div style="width: 180px;">{{form.formCode}}</div>
          </el-form-item>
          <el-form-item label="创建时间">
            <div style="width: 180px;">{{new Date(form.createAt).format()}}</div>
          </el-form-item>
          <el-form-item label="销售类型" v-if="$erp.enable()">
            <div style="width: 180px;">{{form.salesTypeName}}</div>
          </el-form-item>
          <el-form-item label="销售人员">
            <div class="h c">
              <el-input v-model="form.salesmanName" :maxlength="40" clearable placeholder="请填写销售人员" style="width: 180px;" v-if="customizeSalesman" />
              <quick-select v-model="form.salesmanId" :label.sync="form.salesmanName" filterable clearable :url="`api/shop/users?shopId=${form.shopId}`" value-field="id" display-field="userRealName" placeholder="请选择销售人员" style="width: 180px;" v-else />
              <el-button style="margin-left: 5px;" @click="toggleCustomizeSalesman">{{customizeSalesman ? "选择" : "手填"}}</el-button>
            </div>
            <!-- <div style="width: 180px;">{{form.salesmanName}}</div> -->
          </el-form-item>
          <el-form-item label="合同号">
            <el-input v-model.trim="form.contractNo" :maxlength="30" placeholder="请输入商场合同号" style="width: 180px;" @change="doCheckContractNo" />
            <!-- <div style="width: 180px;">{{form.contractNo}}</div> -->
          </el-form-item>
        </div>

        <div class="h">
          <el-form-item prop="customerName" label="客户姓名">
            <el-input v-model.trim="form.customerName" :maxlength="20" style="width: 180px;" placeholder="客户姓名" />
          </el-form-item>
          <el-form-item prop="customerPhone" label="手机号码">
            <el-input v-model.trim="form.customerPhone" :maxlength="11" style="width: 180px;" placeholder="输入手机号码" />
          </el-form-item>
          <el-form-item prop="purchaseTime" label="单据日期">
            <el-date-picker v-model.trim="form.purchaseTime" type="date" :picker-options="purchaseTimeOptions" format="yyyy-MM-dd" value-format="timestamp" style="width: 180px;" placeholder="实际下单据日期" />
          </el-form-item>
          <!-- <el-form-item prop="deliveryDate" label="送货日期">
              <el-date-picker v-model.trim="form.deliveryDate" type="date" style="width: 180px;" value-format="timestamp" placeholder="客户期望送货日期"></el-date-picker>
          </el-form-item>-->
          <el-form-item prop="fullPaymentShip" label="全款发货">
            <!-- <el-checkbox v-model="form.fullPaymentShip"></el-checkbox> -->
            <el-switch v-model="form.fullPaymentShip" :disabled="!checkPermission(['RETAILMANAGE_SHIP'])"></el-switch>
          </el-form-item>
        </div>

        <div class="h s">
          <el-form-item label="收货地址" prop="provinceCode">
            <quick-select v-model="form.provinceCode" :label.sync="form.provinceName" url="api/regionalDict/province" value-field="code" placeholder="选择省份" filterable style="width: 180px;" @change="form.cityCode = null; form.districtCode = null;" />
          </el-form-item>
          <el-form-item label-width="10px" prop="cityCode" style="width: 185px;">
            <quick-select v-model="form.cityCode" :label.sync="form.cityName" :url="`api/regionalDict/parent/${form.provinceCode}`" value-field="code" placeholder="选择城市" filterable v-if="form.provinceCode" style="width: 100%;" @change="form.districtCode = null" />
            <el-input disabled placeholder="请先选择省份" v-else />
          </el-form-item>
          <el-form-item label-width="10px" prop="districtCode" style="width: 185px;">
            <quick-select v-model="form.districtCode" :label.sync="form.districtName" :url="`api/regionalDict/parent/${form.cityCode}`" value-field="code" placeholder="选择地区" filterable v-if="form.cityCode" style="width: 100%;" />
            <el-input disabled placeholder="请先选择城市" v-else />
          </el-form-item>
          <el-form-item label-width="10px" prop="customerAddress" class="flex">
            <el-input v-model="form.customerAddress" :maxlength="100" class="flex" placeholder="请输入详细地址" />
          </el-form-item>
        </div>

        <el-form-item prop="items" label="销售商品">
          <template v-if="form.shopId">
            <sku-selector request-url="api/shop/salesGoods" retail shelf @submit="handleGoodsAdd" />
            <el-table :data="form.items" row-key="id" :row-class-name="getRowClassName" empty-text="还没有添加任何商品">
              <el-table-column prop="code" label="商品编码" min-width="140" fixed />
              <el-table-column prop="erpCode" label="ERP编码" min-width="140" fixed />
              <el-table-column label="商品" min-width="180" fixed>
                <div style="line-height: 1.5;" slot-scope="scope">
                  <div>{{scope.row.goodsName}}</div>
                  <div class="fc-g">{{$goodsSpecConvert(scope.row.specs)}}</div>
                </div>
              </el-table-column>
              <el-table-column width="100">
                <template slot-scope="scope">
                  <template v-if="scope.row._isAdd">
                    <el-select size="mini" v-model="scope.row.itemType" @change="handleGoodaItemTypeChange(scope.row)" style="width: 100%;" v-if="$erp.enable()">
                      <el-option v-for="(v, k) in goodsItemTypes" :key="k" :label="v.label" :value="k" />
                    </el-select>
                    <el-select size="mini" v-model="scope.row.itemType" @change="handleGoodaItemTypeChange(scope.row)" style="width: 100%;" v-else>
                      <el-option v-for="(v, k) in goodsItemTypesDis" :key="k" :label="v.label" :value="k" />
                    </el-select>
                  </template>
                  <span v-else>{{goodsItemTypes[scope.row.itemType].label}}</span>
                </template>
              </el-table-column>
              <el-table-column label="销售价" width="140">
                <template slot-scope="scope">
                  <price-input v-model="scope.row.realPrice" :min="0" size="mini" style="width: 100%;" v-if="scope.row._editable" />
                  <span v-else>{{$price(scope.row.realPrice)}}</span>
                </template>
              </el-table-column>

              <el-table-column label="数量" width="120">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.quantity" :min="scope.row._minQuantity" :max="9999" :step="1" :precision="2" size="mini" @change="hasChanged = true; $checkNaN(scope.row, 'quantity', scope.row._minQuantity)" controls-position="right" style="width: 100%;" v-if="scope.row._editable" />
                  <span v-else>{{scope.row.quantity}}</span>
                </template>
              </el-table-column>
              <el-table-column label="已发货数量" prop="sendCount" width="120" />
              <el-table-column label="已关闭数量" prop="cancelCount" width="120" />
              <el-table-column label="金额合计" width="90" align="center">
                <template slot-scope="scope">{{$price(scope.row.realPrice * scope.row.quantity)}}</template>
              </el-table-column>

              <el-table-column label="成本单价" width="100px" v-if="checkPermission(['PURCHASE_ALL', 'PURCHASE_TOTALMONEY'])">
                <template slot-scope="scope">{{ scope.row.purchasePrice ? $price(scope.row.purchasePrice) : '未知' }}</template>
              </el-table-column>
              <el-table-column label="成本小计" width="100px" v-if="checkPermission(['PURCHASE_ALL', 'PURCHASE_TOTALMONEY'])">
                <template slot-scope="scope">{{ scope.row.purchasePrice ? $price(scope.row.purchasePrice * (scope.row.quantity - scope.row.cancelCount)) : '未知' }}</template>
              </el-table-column>

              <el-table-column label="现场提货" width="70" align="center">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.sendType" :true-label="0" :false-label="1" :disabled="!scope.row._isAdd" />
                </template>
              </el-table-column>
              <el-table-column label="交货日期" width="150" align="center">
                <template slot-scope="scope">
                  <el-date-picker v-model.trim="scope.row.deliveryDate" :clearable="false" size="mini" type="date" value-format="timestamp" placeholder="交货日期" style="width: 100%;" v-if="scope.row._editable" />
                  <span v-else>{{new Date(scope.row.deliveryDate).format("yyyy-MM-dd")}}</span>
                </template>
              </el-table-column>
              <el-table-column label="库房" width="155">
                <template slot-scope="scope">
                  <quick-select v-model="scope.row.warehouseId" filterable url="api/warehouse" placeholder="请选择发货库房" clearable v-if="scope.row._isAdd" />
                  <span v-else>{{getWarehouse(scope.row.warehouseId)}}</span>
                </template>
              </el-table-column>
              <el-table-column label="备注" width="150">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.info" size="mini" :maxlength="100" placeholder="最大100字符" v-if="scope.row._editable" />
                  <span v-else>{{scope.row.info}}</span>
                </template>
              </el-table-column>
              <el-table-column width="40" align="right" fixed="right">
                <div class="row-commands" slot-scope="scope" v-if="scope.row._editable">
                  <el-button type="text" size="mini" class="danger" icon="el-icon-delete" @click="handleGoodsRemove(scope.row)"></el-button>
                </div>
              </el-table-column>
            </el-table>
          </template>
        </el-form-item>
        <el-form-item label="优惠券">
          <el-input v-model="form.couponRemark" :maxlength="50" placeholder="请输入优惠券名称" style="width: 370px" />
        </el-form-item>
        <el-form-item prop="source" label="订单来源">
          <quick-select v-model="form.source" :options="orerSourceOptions" clearable display-field="name" value-field="name" filterable placeholder="请选择订单来源" style="width: 370px;" v-if="orerSourceOptions && orerSourceOptions.length" />
          <el-input v-model.trim="form.source" :maxlength="200" placeholder="请填写订单来源" style="width:370px" v-else />
        </el-form-item>
        <el-form-item prop="info" label="备注">
          <el-input type="textarea" v-model.trim="form.info" :maxlength="100" placeholder="有其他要求或需要说明的情况，请在备注中说明" resize="none" :rows="2" />
        </el-form-item>
        <el-form-item label="附件">
          <file-uploader :entity-id="form.fileId" folder="retail/attachments" accept multiple :max-count="9" :thumbnail-size="64" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" :loading="saving" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import checkPermission from "@/utils/permission";
import { get, changeOrder, checkContractNo } from "@/api/shopOrder";
import { getSkuRetailPrice } from "@/api/goods";
import { getGoodsByShopId } from "@/api/warehouse";
import skuSelector from "@/views/assembly/skuSelect";
import request from "@/utils/request";

export default {
  components: { skuSelector },
  props: {
    orderId: String,
    visible: Boolean,
  },
  data() {
    const checkItemInfos = function (rule, value, callback) {
      let err = null;
      if (value && value.length) {
        for (let i = 0, l = value.length; i < l; i++) {
          if (!value[i].deliveryDate) {
            err = "请选择交货日期";
            break;
          }
        }
      }
      if (err) {
        callback(new Error(err));
      } else {
        callback();
      }
    };

    return {
      now: null,
      loading: false,
      saving: false,
      show: false,
      originalOrder: null,
      warehouses: [],
      orerSourceOptions: [],
      form: null,
      customizeSalesman: false,
      purchaseTimeOptions: {
        disabledDate: (d) => {
          let nd = d.getTime();
          return nd > this.now;
        },
      },
      goodsItemTypes: {
        sale: { label: "商品", type: "" },
        gift: { label: "赠品", type: "warning" },
        simple: { label: "出样", type: "info" },
      },
      goodsItemTypesDis: {
        sale: { label: "商品", type: "" },
        gift: { label: "赠品", type: "warning" },
      },
      rules: {
        salesTypeId: [
          { required: true, message: "请选择销售类型", trigger: "change" },
        ],
        provinceCode: [
          { required: true, message: "请选择省份", trigger: "change" },
        ],
        cityCode: [
          { required: true, message: "请选择城市", trigger: "change" },
        ],
        districtCode: [
          { required: true, message: "请选择地区", trigger: "change" },
        ],
        customerAddress: [
          { required: true, message: "收货地址为必填项", trigger: "blur" },
        ],
        customerName: [
          { required: true, message: "请填写客户名称", trigger: "blur" },
        ],
        shopId: [{ required: true, message: "请选择门店", trigger: "blur" }],
        salesmanId: [{ required: true, message: "请选择销售人员" }],
        customerPhone: [
          { required: true, message: "请填写客户手机号码" },
          {
            pattern: /^\d{11}$/gi,
            message: "手机号码格式不正确",
          },
        ],
        items: [
          { required: true, message: "请选择要销售的商品" },
          { type: "array", min: 1, message: "请选择要销售的商品" },
          { validator: checkItemInfos },
        ],
        prePay: [
          { required: true, message: "请填写已付款金额", trigger: "blur" },
        ],
        auditRemark: [{ required: true, message: "请填写审批备注信息" }],

        contractNo: [{ required: true, message: "请填写合同号" }],
      },
    };
  },
  watch: {
    visible: function (val) {
      if (val !== this.show) {
        this.show = val;
      }
    },
    show: function (val) {
      this.$emit("update:visible", val);
      if (val === false) {
        this.$emit("update:orderId", null);
      }
    },
    orderId: {
      immediate: true,
      handler: "load",
    },
  },
  methods: {
    checkPermission,
    getWarehouse(warehouseId) {
      let wName = null;
      if (warehouseId) {
        (this.warehouses || []).forEach((o) => {
          if (warehouseId == o.id) {
            wName = o.name;
          }
        });
      }
      return wName;
    },
    getShoOrderSource() {
      request({
        url: "api/shoOrderSource",
        method: "get",
        params: {
          enabled: true,
          page: 0,
          size: 9999,
          sort: "createAt,desc"
        }
      }).then((res) => {
        if (res && res.content && res.content.length > 0) {
          this.orerSourceOptions = res.content;
          if (this.$erp.enable()) {
            this.$set(this.rules, "source", [{ required: true, message: "请选择订单来源" }]);
          }
        }
      });
    },
    loadWarehouses() {
      request({
        url: "api/warehouse",
        method: "get",
        params: {
          page: 0,
          size: 1000,
        },
      }).then((res) => {
        if (res) {
          this.warehouses = res.content;
        }
      });
    },
    load() {
      this.originalOrder = null;
      this.form = null;
      this.now = this.$now.get();
      if (this.orderId) {
        this.loading = true;
        get(this.orderId)
          .then((res) => {
            delete res.payItems;
            res.items = (res.items || []).filter(
              (o) => o.changeType !== "CANCEL"
            );
            res.items.forEach((o) => {
              o._isAdd = false;
              o._minQuantity = o.sendCount || 0;
              o._stockInfo = {
                loading: false,
                error: false,
                values: null,
              };
              o._editable = !o.sendCount && !o.deliveryCount && !o.cancelCount;
              // this.loadStockInfo(o, res.shopId);
            });
            this.originalOrder = res;
            this.customizeSalesman = !res.salesmanId;
            this.form = JSON.parse(JSON.stringify(res));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    loadStockInfo(g, shopId = null) {
      if (g && g.goodsId) {
        g._stockInfo.loading = true;
        g._stockInfo.error = false;
        g._stockInfo.values = {};
        let _purchasePrice = !(g.purchasePrice && g.purchasePrice > 0);
        getGoodsByShopId(g.goodsId, shopId || this.form.shopId, _purchasePrice)
          .then((res) => {
            g._stockInfo.values = res;
            if (res.money && res.money >= 0) {
              g.purchasePrice = res.money;
            } else {
              g._stockInfo.values.money = g.purchasePrice;
            }
          })
          .catch((err) => {
            g._stockInfo.error = "获取失败";
          })
          .finally((_) => {
            g._stockInfo.loading = false;
          });
      }
    },

    loadGoodsPrice(row) {
      row._price.loading = true;
      row._price.error = null;
      getSkuRetailPrice(this.form.shopId, row.goodsId)
        .then((res) => {
          if (isNaN(res)) {
            row._price.error = "获取失败";
            row.price = 0;
            row.realPrice = 0;
          } else {
            row.realPrice = row.price = parseInt(res);
          }
        })
        .catch((err) => {
          row._price.error = "获取失败";
          row.price = 0;
          row.realPrice = 0;
        })
        .finally((_) => {
          row._price.loading = false;
        });
    },
    getRowClassName(scope) {
      return scope.row.price != null && scope.row.price < scope.row.realPrice
        ? "el-table--wraning-row"
        : "";
    },

    doCheckContractNo() {
      if (this.form && !this.form.id && this.form.contractNo) {
        checkContractNo(this.form.contractNo).then((res) => {
          if (!res) {
            this.$alert("该合同号已存在，建议进行更换。", "系统提示", {
              type: "warning",
            });
          }
        });
      }
    },

    handleGoodsAdd(list) {
      if (list && list.length) {
        this.hasChanged = true;
        let ds = this.form.items || [];
        let now = new Date(this.now);
        list.forEach((o) => {
          let _no = {
            id: this.$uuid(),
            discountRate: 10000,
            spuId: o.spuId,
            goodsId: o.id,
            brandId: o.brandId,
            brandName: o.brandName,
            seriesId: o.seriesId,
            seriesName: o.seriesName,
            goodsCategoryId: o.categoryId,
            goodsCategoryName: o.categoryName,
            goodsName: o.name,
            deliveryDate: null,
            specs: o.specs,
            itemType: "sale",
            price: 0,
            realPrice: 0,
            _price: {
              loading: false,
              error: null,
            },
            _stockInfo: {
              loading: false,
              error: false,
              values: null,
            },
            code: o.code,
            erpCode: o.erpCode,
            quantity: 1,
            cancelCount: 0,
            sendType: 1,
            discountAmount: 0,
            info: "",
            warehouseId: null,
            shoPromotionId: null,
            _isAdd: true,
            _minQuantity: 0.01,
            _editable: true,
            purchasePrice:null
          };
          let dc = o.deliveryCycle || 7;
          _no.deliveryDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + dc
          ).getTime();
          ds.push(_no);
          this.loadGoodsPrice(_no);
          this.loadStockInfo(_no);
        });
        this.form.items = ds;
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },

    handleGoodsRemove(row) {
      if (this.form && this.form.items && this.form.items.length) {
        let inx = this.form.items.findIndex((o) => {
          return o.id === row.id;
        });
        if (inx >= 0) this.form.items.splice(inx, 1);
      }
    },

    handleGoodaItemTypeChange(row) {
      if (row.itemType === "sale") {
        this.loadGoodsPrice(row);
      } else {
        row.realPrice = 0;
        row.price = 0;
      }
    },

    handleSubmit() {
      this.$confirm(
        "请仔细核对订单信息，当前操作不可取消和恢复，确定要进行变更吗？",
        "操作确认",
        {
          type: "warning",
        }
      ).then(() => {
        let cf = JSON.parse(JSON.stringify(this.form)),
          bf = JSON.parse(JSON.stringify(this.originalOrder));
        let items = [];
        cf.items.forEach((item) => {
          delete item._isAdd;
          delete item._stockInfo;
          delete item._price;
          delete item._minQuantity;
          delete item._editable;
          // 查找原始项
          let oItem = bf.items.find((o) => o.id === item.id);
          if (oItem) {
            delete oItem._isAdd;
            delete oItem._stockInfo;
            delete oItem._price;
            delete oItem._minQuantity;
            delete oItem._editable;
            // 如果找到原始项，进行对比
            let itemStr = JSON.stringify(item),
              oItemStr = JSON.stringify(oItem);
            if (itemStr !== oItemStr) {
              // 如果有变更，则记录变更信息
              items.push({
                saleItemId: item.id,
                changeBefore: oItemStr,
                changeAfter: itemStr,
                changeType: "UPDATE",
              });
            }
          } else {
            items.push({
              saleItemId: null,
              changeBefore: null,
              changeAfter: JSON.stringify(item),
              changeType: "ADD",
            });
            // 如果未找到原始项，并是新增项，则记录变更信息
          }
        });

        bf.items.forEach((oItem) => {
          let item = cf.items.find((o) => o.id === oItem.id);
          if (!item) {
            // 如果原始项在新单据中未找到，则记录变更信息
            items.push({
              saleItemId: oItem.id,
              changeBefore: JSON.stringify(oItem),
              changeAfter: null,
              changeType: "CANCEL",
            });
          }
        });

        delete cf.items;
        delete bf.items;
        let cfStr = JSON.stringify(cf),
          bfStr = JSON.stringify(bf);
        let fd = {
          saleFormId: this.orderId,
          changeBefore: bfStr,
          changeAfter: bfStr !== cfStr ? cfStr : null,
          items,
        };

        this.saving = true;
        changeOrder(fd)
          .then((res) => {
            this.$message.success("订单变更成功");
            this.$emit("change");
            this.show = false;
          })
          .finally(() => {
            this.saving = false;
          });
      });
    },

    toggleCustomizeSalesman() {
      this.customizeSalesman = !this.customizeSalesman;
      if (this.customizeSalesman) {
        this.form.salesmanId = "";
        if (!this.originalOrder.salesmanId)
          this.form.salesmanName = this.originalOrder.salesmanName;
      } else if (this.originalOrder.salesmanId) {
        this.form.salesmanId = this.originalOrder.salesmanId;
        this.form.salesmanName = this.originalOrder.salesmanName;
      } else {
        this.form.salesmanId = "";
        this.form.salesmanName = "";
      }
    },
  },
  created() {
    this.loadWarehouses();
    this.getShoOrderSource();
  },
};
</script>