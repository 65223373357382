<template>
  <div class="app-container flex v" v-loading.fullscreen.lock="closing">
    <div class="head-container">
      <el-input v-model.trim="query.salesmanName" clearable placeholder="输入销售人员搜索" class="filter-item" style="width: 180px" @keyup.enter.native="toQuery" />
      <quick-select v-model="query.shopId" url="api/shop" placeholder="门店" clearable filterable @change="toQuery" value-field="id" display-field="shopName" class="filter-item" style="width: 240px;" />
      <quick-select v-model="query.salesmanId" class="filter-item" v-if="query.shopId" filterable clearable style="width: 150px;" :url="`api/users?shopId=${query.shopId}`" value-field="id" display-field="userRealName" placeholder="销售人员" @change="toQuery" />
      <el-select v-model="dateType" placeholder="日期类型" class="filter-item" style="width: 100px" @change="toQuery">
        <el-option v-for="(v,k) in dateTypes" :key="k" :label="v" :value="k" />
      </el-select>
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" style="width: 220px"/>
      <!-- <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="下单日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" style="width: 260px;" @change="toQuery" /> -->
      <!-- <el-date-picker v-model="query.deliveryDateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="送货日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" style="width: 260px;" @change="toQuery" /> -->
      <quick-select ref="salesType" v-model="query.salesTypeId" v-if="$erp.enable()" class="filter-item" filterable clearable style="width: 100px;" url="api/salesType" value-field="id" display-field="name" placeholder="销售类型" @change="toQuery" />
      <el-select v-model="query.statusList" filterable clearable placeholder="状态" class="filter-item" style="width: 170px" @change="toQuery" multiple collapse-tags>
        <el-option v-for="item in status" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-select v-model="query.payStatus" filterable clearable placeholder="收款状态" class="filter-item" style="width: 120px" @change="toQuery">
        <el-option v-for="(v, k) in payStatus" :key="k" :label="v" :value="k" />
      </el-select>
      <el-select v-model="query.changeStatus" filterable clearable placeholder="变更状态" class="filter-item" style="width: 120px" @change="toQuery">
        <el-option v-for="v in changeStatus" :key="v.id" :label="v.label" :value="v.id" />
      </el-select>
      <el-select v-model="query.sendNotice" filterable clearable placeholder="上报状态" class="filter-item" style="width: 120px;" @change="toQuery" v-if="$erp.enable() || checkPermission(['RETAILMANAGE_ALL', 'RETAILMANAGE_APPEAR'])">
        <el-option label="已上报" :value="true" />
        <el-option label="未上报" :value="false" />
      </el-select>
      <!-- <el-select v-model="query.isChanceOrder" clearable placeholder="是否商机订单" class="filter-item" style="width: 140px;" @change="toQuery">
        <el-option label="商机订单" :value="true" />
        <el-option label="标准订单" :value="false" />
      </el-select>-->
      <el-select v-model="query.isMiniProgram" filterable clearable placeholder="操作来源" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="小程序" :value="true" />
        <el-option label="电脑端" :value="false" />
      </el-select>
      <price-range-input :start.sync="query.realTotalMoneyMin" :end.sync="query.realTotalMoneyMax" start-placeholder="订单金额" style="width: 150px;" class="filter-item" placeholder="订单金额" @change="toQuery" />
      <el-input v-model="query.keywords" class="filter-item" :maxlength="20" placeholder="销售单号/合同号/电话号码/客户搜索" @keypress.enter.native="toQuery" style="width: 200px;" />
      <el-input v-model="query.customerPhone" class="filter-item" :maxlength="20" placeholder="电话号码搜索" @keypress.enter.native="toQuery" style="width: 120px;" />
      <el-input v-model="query.address" class="filter-item" :maxlength="20" placeholder="地址搜索" @keypress.enter.native="toQuery" style="width: 150px;" />
      <el-input v-model="query.createBy" class="filter-item" :maxlength="20" placeholder="创建人" @keypress.enter.native="toQuery" style="width: 120px;" />
      <el-checkbox border v-if="checkPermission(['RETAILMANAGE_ALL', 'RETAILMANAGE_APPROVE'])" class="filter-item" v-model="query.meAudited" @change="toQuery">待我审批</el-checkbox>
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button v-permission="['RETAILMANAGE_CREATE']" class="filter-item" type="primary" icon="el-icon-plus" @click="add">新建</el-button>

      <el-button v-permission="['RETAILMANAGE_SUBMIT_BATCH']" class="filter-item" type="primary" icon="el-icon-check" @click="toBatchSubmit" :disabled="!selection || !selection.length">批量提交</el-button>
      <el-button v-permission="['RETAILMANAGE_APPEAR_BATCH']" class="filter-item" type="danger" icon="el-icon-upload2" @click="toBatchReport" :disabled="!selection || !selection.length">批量上报</el-button>

      <el-button type="primary" size="mini" class="filter-item" @click="importRetail" icon="el-icon-upload2" v-if="checkPermission(['RETAILMANAGE_ALL', 'RETAILMANAGE_IMPORT'])">导入</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>

      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table ref="list" v-loading="loading" row-key="id" :data="data" border :default-sort="{ prop: 'purchaseTime', order: 'descending' }" @sort-change="handleSortChange" @selection-change="handleSelectionChange" height="100px">
      <el-table-column v-if="checkPermission(['RETAILMANAGE_ALL', 'RETAILMANAGE_SUBMIT_BATCH', 'RETAILMANAGE_APPEAR_BATCH'])" type="selection" width="40" align="center" :selectable="checkCheckable" fixed />
      <el-table-column prop="formCode" label="销售单号" width="120" fixed />
      <el-table-column label="销售类型" width="90" :formatter="formatSaleType" v-if="$erp.enable()" show-overflow-tooltip />
      <!-- <el-table-column label="ERP单号" width="110" :formatter="v=>{ return v.formCode===v.erpFormCode?'':v.erpFormCode}" v-if="$erp.enable()" /> -->
      <el-table-column prop="contractNo" label="商场合同号" width="100" show-overflow-tooltip />
      <el-table-column prop="shopName" label="门店" min-width="240" show-overflow-tooltip />
      <el-table-column prop="customerName" label="客户名称" width="100" />
      <el-table-column prop="customerPhone" label="客户电话" width="100" show-overflow-tooltip />
      <el-table-column prop="customerAddress" label="客户收货地址" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.provinceName + scope.row.cityName + scope.row.districtName + scope.row.customerAddress }}</template>
      </el-table-column>

      <el-table-column prop="purchaseTime" label="单据日期" width="110" sortable="custom" :formatter="r => { return new Date(r.purchaseTime).format('yyyy/MM/dd'); }" />
      <el-table-column prop="purchaseTime" label="提交日期" width="110" sortable="custom" :formatter="r => { return r.submitAt?new Date(r.submitAt).format('yyyy/MM/dd'):''; }" />
      <el-table-column prop="auditTime" label="审核日期" width="120" sortable="custom" :formatter="r => { return r.auditTime?new Date(r.auditTime).format('yyyy/MM/dd hh:mm'):''; }" />  
      <el-table-column prop="realTotalMoney" label="总金额" width="120" align="right" :formatter="$price" />
      <el-table-column label="已收款" width="120" align="right">
        <template slot-scope="scope">
          <span :class="scope.row.payAmount >= scope.row.realTotalMoney ? 'fc-s' : ''">{{ $price(scope.row.payAmount) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="auditAmount" label="已审收款" min-width="120" align="right" :formatter="$price" />
      <el-table-column label="待收款" width="120" align="right">
        <template slot-scope="scope">
          <span :class="scope.row.payAmount < scope.row.realTotalMoney ? 'fc-e' : ''">{{ $price(scope.row.realTotalMoney - scope.row.payAmount) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="折扣率" width="80" align="right" :formatter="calcDiscountPercent" />
      <!-- <el-table-column prop="deliveryDate" label="交货日期" width="90" :formatter="r => { return r.deliveryDate?new Date(r.deliveryDate).format('yyyy/MM/dd'):''; }" /> -->
      <el-table-column prop="salesmanName" label="销售人员" width="100" show-overflow-tooltip />
      <!-- <el-table-column label="商机订单" width="70" align="center">
        <template slot-scope="scope">
          <i class="fc-p el-icon-success fs-large" v-if="scope.row.isChanceOrder"></i>
        </template>
      </el-table-column>-->
      <!-- <el-table-column prop="deliveryTime" label="发货日期" width="90">
        <template slot-scope="scope">
          <span :class="scope.row.deliveryTime < $now.get() + 72 *3600 * 1000 ? 'fc-e' : ''">{{ scope.row.deliveryTime ? new Date(scope.row.deliveryTime).format('yyyy/MM/dd') : ''}}</span>
        </template>
      </el-table-column>-->
      <el-table-column prop="info" label="备注" min-width="160" show-overflow-tooltip />
      <el-table-column prop="auditRemark" label="最近一次审批备注" width="140" show-overflow-tooltip />
      <el-table-column prop="auditRoleName" label="待审角色" width="90" show-overflow-tooltip />
      <el-table-column prop="fullPaymentShip" label="全款发货" width="90">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.fullPaymentShip">是</el-tag>
          <el-tag type="info" v-else>否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createBy" label="创建人" width="90" show-overflow-tooltip />
      <el-table-column prop="deviceInfo" label="操作来源" width="90" show-overflow-tooltip />
      <el-table-column prop="changeStatus" label="变更状态" width="100">
        <template slot-scope="scope">
          <dot type="gray" same v-if="scope.row.changeStatus === null">无变更</dot>
          <dot :type="changeStatus[scope.row.changeStatus+1].type" same v-else>{{changeStatus[scope.row.changeStatus+1].label}}</dot>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100" align="center" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{ status[scope.row.status].label }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="已审收款提示" width="100" align="center" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="scope.row.auditAmount >= scope.row.payAmount && scope.row.payAmount >= scope.row.realTotalMoney ? 'success' : 'info'">{{ getPayStatus(scope.row.realTotalMoney, scope.row.payAmount, scope.row.auditAmount) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="已上报" width="60" fixed="right" align="center" v-if="$erp.enable() || checkPermission(['RETAILMANAGE_ALL', 'RETAILMANAGE_APPEAR'])">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.sendNotice"></i>
        </template>
      </el-table-column>
      <el-table-column width="150" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" v-permission="['RETAILMANAGE_ALL', 'RETAILMANAGE_EDIT']" type="text" @click="edit(scope.row)" v-if="scope.row.status === 'input' || scope.row.status === 'callback'">编辑</el-button>
          <el-button size="mini" type="text" @click="edit(scope.row)" v-else>查看</el-button>
          <!-- 审核 -->
          <el-button size="mini" v-permission="['RETAILMANAGE_ALL', 'RETAILMANAGE_REFUND']" type="text" @click="pay(scope.row)">收/退款</el-button>

          <el-dropdown>
            <el-button type="text" size="mini">
              更多
              <i class="el-icon-arrow-down"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-permission="['RETAILMANAGE_ALL', 'RETAILMANAGE_APPROVE']" @click.native="view(scope.row, true)" v-if="scope.row.allowCurrentAudit && (scope.row.status === 'submit' || scope.row.status === 'auditing')">审批</el-dropdown-item>
              <el-dropdown-item v-permission="['RETAILMANAGE_CANCEL']" @click.native="cancelAudit(scope.row)" v-if="scope.row.status === 'pass' && !scope.row.sendNotice">反审核</el-dropdown-item>

              <el-dropdown-item v-permission="['RETAILMANAGE_ALL', 'RETAILMANAGE_APPEAR']" @click.native="doReport(scope.row)" v-if="(scope.row.status === 'pass' || scope.row.status === 'unsend' || scope.row.status === 'sended' || scope.row.status === 'sendPart' || scope.row.status === 'closedPart') && !scope.row.sendNotice">上报</el-dropdown-item>
              <el-dropdown-item v-permission="['RETAILMANAGE_ALL', 'RETAILMANAGE_SEND']" @click.native="edit(scope.row, 'outbound')" v-if="((scope.row.fullPaymentShip && scope.row.payAmount >= scope.row.realTotalMoney) || !scope.row.fullPaymentShip) && (scope.row.status === 'pass' || scope.row.status === 'unsend' || scope.row.status === 'sendPart' || scope.row.status === 'closedPart')">发货</el-dropdown-item>
              <el-dropdown-item v-permission="['RETAILMANAGE_CHANGE']" @click.native="change(scope.row)" v-if="!scope.row.sendNotice && scope.row.changeStatus !== 0 && (scope.row.status === 'pass' || scope.row.status === 'closedPart' || scope.row.status === 'unsend' || scope.row.status === 'sendPart' || scope.row.status === 'sended')">变更</el-dropdown-item>
              <el-dropdown-item v-permission="['RETAILMANAGE_ALL', 'RETAILMANAGE_CLOSE']" @click.native="close(scope.row)" v-if="scope.row.status === 'pass' || scope.row.status === 'unsend'">关闭</el-dropdown-item>

              <template v-if="scope.row.status === 'pass' || scope.row.status === 'unsend' || scope.row.status === 'sendPart' || scope.row.status === 'sended' || scope.row.status === 'cancel' || scope.row.status === 'closed' || scope.row.status === 'closedPart'">
                <el-dropdown-item divided @click.native="print(scope.row)">打印</el-dropdown-item>
                <el-dropdown-item @click.native="print(scope.row, true)">打印（含统一零售价）</el-dropdown-item>
              </template>

              <el-dropdown-item v-permission="['RETAILMANAGE_DELETE']" @click.native="subDelete(scope.row)" v-if="(scope.row.status === 'input' || scope.row.status === 'cancel' || scope.row.status === 'closed') && deleteOther(scope.row)">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <eForm ref="form" @change="init" />
    <report ref="report" />
    <pays ref="pays" @pay-completed="init" />
    <importForm ref="importForm" />

    <change-dialog :order-id.sync="changer.orderId" :visible.sync="changer.visible" @change="init" />

    <batch-submit ref="batchSubmit" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";
import pays from "./pays";
import report from "./report";
import { del, close as closeOrder, cancelAudit } from "@/api/shopOrder";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import PriceRangeInput from "@/views/components/common/priceRangeInput";
import { mapGetters } from "vuex";
import importForm from "./retail-import";
import changeDialog from "./change";
import batchSubmit from "./batchSubmit";

export default {
  components: {
    eForm,
    pays,
    report,
    PriceRangeInput,
    importForm,
    changeDialog,
    batchSubmit,
  },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      cancelLoading: false,
      closing: false,
      downloadLoading: false,
      selection: null,
      dateTypes: {
        sale: "销售日期",
        audit: "审核日期",
      },
      dateType: "sale",
      //时间查询
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      payStatus: {
        all: "全部已收",
        part: "部分已收",
        zero: "待收款",
      },
      status: {
        input: { key: "input", label: "录入状态", type: "info" },
        submit: { key: "submit", label: "提交状态", type: "" },
        auditing: { key: "auditing", label: "审核中", type: "" },
        pass: { key: "pass", label: "审核通过", type: "success" },
        fail: { key: "fail", label: "审批未通过", type: "danger" },
        callback: { key: "callback", label: "退回修改", type: "info" },
        // unsend: { key: "unsend", label: "待发货", type: "info" },
        sended: { key: "sended", label: "已发货", type: "success" },
        sendPart: { key: "sendPart", label: "部分已发货", type: "success" },
        cancel: { key: "cancel", label: "已取消", type: "info" },
        closed: { key: "closed", label: "已关闭", type: "info" },
        closedPart: { key: "closedPart", label: "部分已关闭", type: "info" },
      },
      changeStatus: [
        { id: -1, label: "未变更", type: "gray" },
        { id: 0, label: "待审核", type: "gray" },
        { id: 1, label: "审核通过", type: "success" },
        { id: 2, label: "审核未通过", type: "danger" },
      ],
      query: {
        shopId: null,
        dateRange: null,
        deliveryDateRange: null,
        status: null,
        payStatus: null,
        keywords: null,
        address: null,
        sendNotice: null,
        isChanceOrder: null,
        realTotalMoneyMin:null,
        realTotalMoneyMax:null,
        statusList: [
          "input",
          "submit",
          "auditing",
          "pass",
          "sended",
          "sendPart",
          "callback",
          "fail",
          "closedPart",
        ],
      },
      sort: ["purchaseTime,desc","formCode,desc"],
      changer: {
        orderId: null,
        visible: false,
      },
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    checkPermission,
    deleteOther(row) {
      return (
        this.checkPermission(["RETAILMANAGE_ALL", "RETAILMANAGE_EDIT_OTHER"]) ||
        row.createById === this.user.id ||
        !row.createById
      );
    },
    beforeInit() {
      this.url = "api/shop/form";
      let query = JSON.parse(JSON.stringify(this.query));
      // if (query.dateRange && query.dateRange.length === 2) {
      //   query.beginTime = query.dateRange[0];
      //   query.endTime = query.dateRange[1];
      // }
      // delete query.dateRange;
      if (
        query.dateRange &&
        query.dateRange.length === 2 &&
        this.dateType === "sale"
      ) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }

      if (
        query.dateRange &&
        query.dateRange.length === 2 &&
        this.dateType === "audit"
      ) {
        query.auditBegTime = query.dateRange[0];
        query.auditEndTime = query.dateRange[1];
      }
      delete query.dateRange;
      if (query.deliveryDateRange && query.deliveryDateRange.length === 2) {
        query.deliveryDateMin = query.deliveryDateRange[0];
        query.deliveryDateMax = query.deliveryDateRange[1];
      }
      delete query.deliveryDateRange;
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: this.sort },
        query
      );
      return true;
    },
    cancelAudit(data) {
      this.$confirm(
        "确定反审核销售单【" + data.formCode + "】吗？",
        "操作确认",
        {
          type: "warning",
        }
      ).then(() => {
        this.cancelLoading = true;
        cancelAudit(data.id)
          .then((res) => {
            this.init();
            this.$notify({
              title: "反审核销售单成功",
              type: "success",
              duration: 2500,
            });
          })
          .finally(() => {
            this.cancelLoading = false;
          });
      });
    },
    calcDiscountPercent(row) {
      let dm = row.totalMoney - row.realTotalSourceMoney;
      if (dm <= 0) return "100%";
      else
        return (
          ((row.realTotalSourceMoney / row.totalMoney) * 100).toFixed(2) + "%"
        );
    },
    subDelete(data) {
      this.$confirm("确定删除销售单【" + data.formCode + "】吗？", "操作确认", {
        type: "warning",
      }).then(() => {
        this.delLoading = true;
        del(data.id)
          .then((res) => {
            this.delLoading = false;
            this.dleChangePage();
            this.init();
            this.$notify({
              title: "删除销售单成功",
              type: "success",
              duration: 2500,
            });
          })
          .finally(() => {
            this.delLoading = false;
          });
      });
    },
    formatSaleType(row) {
      let v = "直营店销售",
        el = this.$refs.salesType;
      if (el && row.salesTypeId) {
        v = el.getDisplayText(row.salesTypeId);
      }
      return v;
    },
    handleSortChange(e) {
      if (e.prop) {
        if (e.order === "ascending") {
          this.sort = `${e.prop},asc`;
          this.toQuery();
        } else if (e.order === "descending") {
          this.sort = `${e.prop},desc`;
          this.toQuery();
        }
      }
    },
    handleSelectionChange(sels) {
      this.selection = sels;
    },
    checkCheckable(data) {
      if (this.selection && this.selection.length) {
        let fstatus = this.selection[0].status;
        if (fstatus === "input") {
          return data.status === "input";
        }
        return (
          (data.status === "pass" ||
            data.status === "unsend" ||
            data.status === "sended" ||
            data.status === "sendPart" ||
            data.status === "closedPart") &&
          !data.sendNotice
        );
      }
      return (
        ((data.status === "pass" ||
          data.status === "unsend" ||
          data.status === "sended" ||
          data.status === "sendPart" ||
          data.status === "closedPart") &&
          !data.sendNotice) ||
        data.status === "input"
      );
    },
    add() {
      this.$refs.form && this.$refs.form.resetForm();
    },
    edit(data, action = null) {
      this.$refs.form && this.$refs.form.resetForm(data, false, action);
    },
    pay(data) {
      this.$refs.pays && this.$refs.pays.show(data);
    },
    view(data, auditable) {
      this.$refs.form && this.$refs.form.resetForm(data, auditable);
    },
    change(data) {
      if (data) {
        this.changer.orderId = data.id;
        this.changer.visible = true;
      }
    },
    close(data) {
      this.$confirm(
        `您确定要关闭销售订单【${data.formCode}】吗？`,
        "操作确认",
        {
          type: "warning",
        }
      ).then((_) => {
        this.closing = true;
        closeOrder(data.id)
          .then((_) => {
            this.init();
            this.$alert(
              `销售订单【${data.formCode}】已经成功关闭，请手动登记退款信息。`,
              "关闭销售单成功"
            );
          })
          .finally((_) => {
            this.closing = false;
          });
      });
    },
    print(data, hasPrice = false) {
      this.$print("retail", data.id, { hasPrice });
    },
    doReport(data) {
      this.$refs.report && this.$refs.report.set([data]);
    },
    toBatchReport() {
      if (this.selection && this.selection.length) {
        this.$refs.report && this.$refs.report.set(this.selection);
      }
    },
    toBatchSubmit() {
      if (this.selection && this.selection.length) {
        this.$refs.batchSubmit && this.$refs.batchSubmit.set(this.selection);
      }
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/shop/form/download", this.params)
        .then((result) => {
          downloadFile(result, "零售单", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    getPayStatus(realTotalMoney, payAmount, auditAmount) {
      let a = "待付";
      if (payAmount >= realTotalMoney) a = "付清";
      let b = "待审";
      if (auditAmount >= payAmount && payAmount > 0) b = "已审";
      return a + b;
    },

    importRetail() {
      this.$refs.importForm && this.$refs.importForm.resetForm();
    },
  },
};
</script>